import { Field, Form, Formik } from 'formik';
import { forwardRef, HTMLAttributes } from 'react';
import { Button, Input, Textarea } from 'react-daisyui';
import { twMerge } from 'tailwind-merge';

export const ContactForm = forwardRef<HTMLDivElement, HTMLAttributes<HTMLDivElement>>(({ className, ...props }, ref) => {
  const classes = twMerge('bg-base-200 p-12', className);
  return (
    <div ref={ref} {...props} className={classes}>
      <Formik
        initialValues={{ name: '', email: '', subject: '', message: '' }}
        validate={(values) => {
          const errors: any = {};
          Object.entries(values).forEach(([key, val]) => {
            if (!val) {
              errors[key] = 'Required';
            }
          });
          if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
            errors.email = 'Invalid email address';
          }
          return errors;
        }}
        onSubmit={async (values, { setSubmitting }) => {
          await fetch('/submit', {
            method: 'post',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(values),
          });
          setSubmitting(false);
        }}
      >
        {({ errors, touched, handleSubmit, isSubmitting }) => {
          const errorColor = (key: keyof typeof errors) => errors[key] && touched[key] && 'error';
          return (
            <Form onSubmit={handleSubmit} className="flex flex-col gap-4 form-control">
              <div className="flex justify-between gap-12">
                <Field as={Input} name="name" placeholder="Your Name*" className="w-full" required color={errorColor('name')} />
                <Field as={Input} name="email" type="email" placeholder="Your Email*" className="w-full" required color={errorColor('email')} />
              </div>
              <Field as={Input} name="subject" placeholder="Subject*" required color={errorColor('subject')} />
              <Field as={Textarea} name="message" placeholder="Your message here*" rows={5} required color={errorColor('message')} />
              <Button type="submit" color="primary" loading={isSubmitting} disabled={isSubmitting}>
                Send
              </Button>
            </Form>
          );
        }}
      </Formik>
    </div>
  );
});
