import clsx from 'clsx';
import { useRef } from 'react';
import { Button, Footer, Hero } from 'react-daisyui';

import logo from './assets/logo.png';
import img1 from './assets/pexels-emre-baykara-5553499-768x1024.jpg';
import img2 from './assets/pexels-pixabay-60008-683x1024.jpg';
import img3 from './assets/pexels-tom-swinnen-723905-666x1024.jpg';
import hero1 from './assets/pexels-vlad-chetan-2892618.jpg';
import { ContactForm } from './Contact';

export function App() {
  const form = useRef<HTMLDivElement>(null);
  const cards = [
    { title: 'mining 1', image: img1 },
    { title: 'mining 2', image: img3 },
    { title: 'mining 3', image: img2 },
  ];
  return (
    <main className="">
      <section className="" style={{ background: `url("${hero1}")`, backgroundSize: 'cover' }}>
        <Hero className="h-screen">
          <Hero.Overlay className="bg-opacity-60" />
          <Hero.Content className="text-center text-neutral-content">
            <div className="">
              <img className="w-1/4 mx-auto" src={logo} alt="logo" />
              <div className="w-10/12 mx-auto">
                <h1 data-testid="title" className="text-5xl font-bold my-8">
                  A Full-Service Metals And Minerals Commodity Trading Company
                </h1>
                <Button className="mx-2" color="ghost" variant="outline" onClick={() => form.current?.scrollIntoView({ behavior: 'smooth' })}>
                  Have a question?
                </Button>
              </div>
            </div>
          </Hero.Content>
        </Hero>
      </section>
      <section className="">
        <Hero className="bg-base-200">
          <Hero.Content className="py-16">
            <div className="">
              <h1 className="text-5xl font-bold capitalize">About Us</h1>
              <div className="py-6">
                <p className="mb-4">
                  Rase Gold supports basic industries by providing them with raw materials, such as steel and electric power, essential to worldwide
                  infrastructure development. This Division engages in global mining and trading of iron ore, coal, uranium, base metals, and minor
                  metals. It is also involved in the development and trading of non-ferrous metal materials, the trading of metal materials and
                  products, their recycling, and other businesses related to their use and disposal.
                </p>
                <p>
                  We distribute and market a diverse range of metals and minerals – such as copper, cobalt, zinc, nickel and ferroalloys etc. – and
                  also market aluminium/alumina and iron ore to our customers from around the world.
                </p>
              </div>
            </div>
          </Hero.Content>
        </Hero>
        <section className="">
          <Hero className="bg-base-100">
            <Hero.Content className="py-16">
              <div className="grid grid-cols-2 grid-rows-2 gap-4 items-center w-1/2">
                {cards.map(({ title, image }, i) => (
                  <figure key={i} className={clsx('flex', { 'row-span-2': !i })}>
                    <img className={clsx('w-full object-cover', {})} src={image} alt={title} />
                  </figure>
                ))}
              </div>
              <div className="prose">
                <h1 className="text-5xl"> Metals & Minerals </h1>
                <p>
                  Our Metals & Minerals Division Contributes To The Development Of The Global Economies By Supporting The Stable Supply Of Resources,
                  Energy, Metal Materials And Metal Products.
                </p>
              </div>
            </Hero.Content>
          </Hero>
        </section>
        <Hero className="contact bg-base-300 relative">
          <Hero.Content className="text-center py-11">
            <div>
              <h1 className="text-3xl font-bold mb-8">Are You Looking For The Best Quality Products And Services?</h1>
              <p>We are always glad to answer questions or provide further information.</p>
            </div>
          </Hero.Content>
        </Hero>
        <ContactForm ref={form} />
        <Footer className="p-4 place-content-center bg-base-100">
          <div>
            <p>Copyright © 2022 - All right reserved by Rase Gold Ltd.</p>
          </div>
        </Footer>
      </section>
    </main>
  );
}
